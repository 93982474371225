import React from 'react';
// eslint-disable-next-line import/no-cycle
import { IndicatorsPropertiesByRecipeToRender } from '../components/RecipeItem';
import { IndicatorsPropertiesOfAllRecipesToRender } from '../components/Summarize';
import { IndicatorsWithPreparation } from './frontend.model';
import { displayDate, groupListeItemByProperty } from '../helpers';

export const indicatorsPropertiesOfAllRecipesToRender: IndicatorsPropertiesOfAllRecipesToRender[] = [
  {
    keyForJSX: 1,
    title: { fr: `Nombre total de recettes quittées en cours` },
    render(recipes: IndicatorsWithPreparation[]): JSX.Element {
      const quantityRecipesAborted = recipes.reduce((a: number, c) => {
        if (c.exitReason.type !== 'completed') return a + 1;
        return a;
      }, 0);

      return <span>{quantityRecipesAborted}</span>;
    },
  },
  {
    keyForJSX: 2,
    title: { fr: `Nombre total de recettes terminées` },
    render(recipes: IndicatorsWithPreparation[]): JSX.Element {
      const finishedRecipes = recipes.filter((r) => r.exitReason.type === 'completed');

      return <span>{finishedRecipes.length}</span>;
    },
  },
  {
    keyForJSX: 3,
    title: { fr: `Durée totale constatée des étapes validées` },
    render(recipes: IndicatorsWithPreparation[]): JSX.Element {
      const durationTotalValidatedAllRecipe = recipes.reduce((recipesDuration: number, recipe) => {
        const getCumulatePathDuration = () =>
          recipe.path.reduce((pathsDuration: number, p) => pathsDuration + (p.durationInSeconds || 0), 0);

        return recipesDuration + getCumulatePathDuration();
      }, 0);

      return <span>{durationTotalValidatedAllRecipe} s</span>;
    },
  },
  {
    keyForJSX: 4,
    title: { fr: `Recette terminées avec récurrence` },
    render(recipes: IndicatorsWithPreparation[]): JSX.Element {
      const finishedRecipes = recipes.filter((r) => r.exitReason.type === 'completed');

      const getDiscriminant = (item: IndicatorsWithPreparation) => item.recipeId;
      const groupedFinishedRecipe = groupListeItemByProperty(finishedRecipes, getDiscriminant);
      const finishedRecipeCount = Object.keys(groupedFinishedRecipe)
        .map((key) => `${key} (${groupedFinishedRecipe[key].length})`)
        .join(', ');

      return <span>{finishedRecipeCount}</span>;
    },
  },
  {
    keyForJSX: 5,
    title: { fr: `Nombre total d'alertes SMS patient 'cuisson' lors de la cuisines` },
    render(recipes: IndicatorsWithPreparation[]): JSX.Element {
      const totalSMSPatient = recipes.reduce((a: number, r) => a + r.cookingPatientAlarmSent.length, 0);

      return <span>{totalSMSPatient}</span>;
    },
  },
  {
    keyForJSX: 6,
    title: { fr: `Nombre total d'alertes SMS aidant 'cuisson' lors de la cuisine` },
    render(recipes: IndicatorsWithPreparation[]): JSX.Element {
      const totalSMSCarer = recipes.reduce((a: number, r) => a + r.cookingCarerAlarmEnd.length, 0);

      return <span>{totalSMSCarer}</span>;
    },
  },
];

export const indicatorsPropertiesByRecipeToRender: IndicatorsPropertiesByRecipeToRender[] = [
  {
    keyForJSX: 1,
    title: { fr: 'ID de la recette' },
    recipeProperty: 'recipeId',
  },
  {
    keyForJSX: 2,
    title: { fr: 'Recette' },
    render(recipe: IndicatorsWithPreparation): JSX.Element {
      return <span>{recipe.preparation.name}</span>;
    },
  },
  {
    keyForJSX: 3,
    title: { fr: 'Heure de début' },
    recipeProperty: 'startDate',
    render(recipe: IndicatorsWithPreparation): JSX.Element {
      return <span>{displayDate(recipe.startDate).yearToMinutes()}</span>;
    },
  },
  {
    keyForJSX: 4,
    title: { fr: "Statut de l'aidant" },
    recipeProperty: 'carerOccupation',
  },
  {
    keyForJSX: 5,
    title: { fr: "Prénom de l'aidant concerné" },
    recipeProperty: 'carerFirstName',
  },
  {
    keyForJSX: 6,
    title: { fr: 'Nombre de parts' },
    recipeProperty: 'numberOfPeople',
  },
  {
    keyForJSX: 7,
    title: { fr: 'Motif sortie de la recette' },
    recipeProperty: 'exitReason',
    render(recipe: IndicatorsWithPreparation): JSX.Element {
      const { label } = recipe.exitReason;

      switch (recipe.exitReason.type) {
        case 'completed':
          // eslint-disable-next-line prefer-template
          return <span>{`complète ${label ? '→ ' + label : ''}`}</span>;
        case 'canceled':
          return <span>annulée</span>;
        case 'canceled-after-restart':
          return <span>annulée après redémarrage</span>;
        default:
          return <span>{label ?? 'inconnu'}</span>;
      }
      return (
        <span>
          {recipe?.exitReason?.type} / {recipe?.exitReason?.label}
        </span>
      );
    },
  },
  {
    keyForJSX: 8,
    title: { fr: 'Nombre de tacheN de la recette' },
    recipeProperty: 'nStepsTotal',
  },
  {
    keyForJSX: 9,
    title: { fr: 'Nombre de tacheN-1 de la recette' },
    recipeProperty: 'nm1StepsTotal',
  },
  {
    keyForJSX: 10,
    title: { fr: 'Nombre de tacheN-2 de la recette' },
    recipeProperty: 'nm2StepsTotal',
  },
  {
    keyForJSX: 11,
    title: { fr: 'Nombre de tacheN "équivalentes" validées' },
    render: (recipe) => <span>{recipe?.nEquivalentStepsExit}</span>,
  },
  {
    keyForJSX: 13,
    title: { fr: 'Nombre de tacheN validées' },
    recipeProperty: 'nStepsDone',
  },
  {
    keyForJSX: 14,
    title: { fr: 'Nombre de tacheN-1 validées' },
    recipeProperty: 'nm1StepsDone',
  },
  {
    keyForJSX: 15,
    title: { fr: 'Nombre de tacheN-2 validées' },
    recipeProperty: 'nm2StepsDone',
  },
  {
    keyForJSX: 16,
    title: { fr: 'Durée estimée de la recette' },
    recipeProperty: 'recipeEstimatedDurationInSeconds',
  },
  {
    keyForJSX: 17,
    title: { fr: 'Durée constatée des étapes validées (hors timeout)' },
    render(recipe: IndicatorsWithPreparation): JSX.Element {
      const duration = recipe.path.reduce((a, c) => a + (c.durationInSeconds || 0), 0);

      return <span>{duration} s</span>;
    },
  },
  {
    keyForJSX: 18,
    title: { fr: "Nombre de sollicitation de l'aide" },
    render(recipe: IndicatorsWithPreparation): JSX.Element {
      const qtyHelp = recipe.path.reduce((a, c) => a + c.numberOfHelpRequests, 0);
      return <span>{qtyHelp}</span>;
    },
  },
  {
    keyForJSX: 19,
    title: { fr: 'Nombre de demandes de détail' },
    render(recipe: IndicatorsWithPreparation): JSX.Element {
      const qtyDetail = recipe.path.reduce((a, c) => a + c.numberOfDetailRequests, 0);
      return <span>{qtyDetail}</span>;
    },
  },
  {
    keyForJSX: 20,
    title: { fr: 'Nombre de retour en arrière lors de la recette' },
    render(recipe: IndicatorsWithPreparation): JSX.Element {
      const qtyBack = recipe.path.reduce((a, c) => a + c.numberOfReturnRequests, 0);
      return <span>{qtyBack}</span>;
    },
  },
  {
    keyForJSX: 21,
    title: { fr: "Nombre d'alertes SMS patient 'cuisson' lors de la cuisine" },
    render: (recipe) => <span>{recipe?.cookingPatientAlarmSent?.length}</span>,
  },
  {
    keyForJSX: 22,
    title: { fr: 'Difficulté de la recette (calcul à date)' },
    recipeProperty: 'recipeLevel',
  },
  {
    keyForJSX: 23,
    title: {
      fr: 'Appréciation de la difficulté de la recette → Note pour la préparation de cette recette (0 = très compliqué, 4= au top)',
    },
    recipeProperty: 'patientFeedback',
  },
  /* // Pas utile pour l'instant {
  title: { fr:     'Durées par étape (équivalent N ,
  key,: '3',
  render(recipe: Recipe): JSX.Element {
    const duractionsSteps = recipe.path.map(p => `${p.stepId} ${p.durationInSeconds ?? '?'}s`).join(', ')
    return <span>{duractionsSteps}</span>;
  },
}, */
];
