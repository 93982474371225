import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { lang, Lang } from '../domain/lang';
import { IndicatorsWithPreparation, SentShoppingList } from '../domain/frontend.model';
import { displayDate } from '../helpers';

export type IndicatorsPropertiesOfAllRecipesToRender = {
  keyForJSX: number;
  title: { [key in Lang]: string };
  // recipeProperty?: keyof Recipe;
  render?: (recipes: IndicatorsWithPreparation[]) => JSX.Element;
};

type Props = {
  recipesComputeToRender: IndicatorsPropertiesOfAllRecipesToRender[];
  recipes: IndicatorsWithPreparation[];
  sentShoppingList: SentShoppingList;
};

const Summarize: React.FC<Props> = ({ recipesComputeToRender, recipes, sentShoppingList }) => {
  const sentShoppingData = getSentShoppingData(sentShoppingList);

  return (
    <Box p={4} color="blue.500">
      <Heading pb={4} color="blue.500" as="h3" size="md">
        Indicateurs généraux (toutes recettes)
      </Heading>
      <SimpleGrid columns={2} spacing={5}>
        {recipesComputeToRender.map((item) => {
          let value: unknown = '';

          if (item.render) value = item.render(recipes);

          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Box key={item.keyForJSX} borderWidth="1px" p={4} boxShadow="md" rounded="md">
              <strong>{item.title[lang]}</strong> : {value}
            </Box>
          );
        })}
        <Box borderWidth="1px" p={4} boxShadow="md" rounded="md">
          <strong>{textSentShopping}</strong>: {sentShoppingData}
        </Box>
      </SimpleGrid>
    </Box>
  );
};

const textSentShopping = "Dates d'envoi de liste de course";
const getSentShoppingData = (sentShoppingList: string[]) =>
  sentShoppingList.map((sms) => displayDate(sms).yearToMinutes()).join(', ');

export default Summarize;
