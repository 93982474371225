import React from 'react';

type Props = {
  id: string;
  recipeLabel: string;
  formattedStartDate: string;
};

const RecipeHeader: React.FC<Props> = ({ id, recipeLabel, formattedStartDate }) => {
  return (
    <span>
      {`${formattedStartDate} ${id}`} : <strong>{recipeLabel}</strong>
    </span>
  );
};

export default RecipeHeader;
