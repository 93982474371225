import React, { useEffect, useState } from 'react';
import { Center, Spinner } from '@chakra-ui/react';
import RecipeList from './components/RecipeList';
import { DataWithPreparation } from './domain/frontend.model';
import { mergeIndicatorsWithPreparation } from './domain/inputDataMapper';
import Summarize from './components/Summarize';
import { indicatorsPropertiesOfAllRecipesToRender } from './domain/indicatorsMapper';

const queryParams = new URLSearchParams(window.location.search);
const id = queryParams.get('id');
const token = queryParams.get('token');

const App = (): JSX.Element => {
  const [data, setData] = useState<DataWithPreparation>();

  useEffect(() => {
    const onInit = async () => {
      if (data) return;
      const res = await fetch(getDataVizUrl());
      const dataRes = await res.json();
      setData(mergeIndicatorsWithPreparation(dataRes));
    };
    onInit().then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data)
    return (
      <Center mt={200}>
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Center>
    );
  if (data && !data.recipesIndicators.length) return <div>Aucune recette</div>;

  return (
    <>
      <div>
        {data && (
          <Summarize
            recipesComputeToRender={indicatorsPropertiesOfAllRecipesToRender}
            recipes={data.recipesIndicators}
            sentShoppingList={data.sentShoppingList}
          />
        )}
      </div>
      <div>{data && <RecipeList indicators={data.recipesIndicators.slice(0, 1)} />}</div>
    </>
  );
};

export default App;

function getDataVizUrl() {
  let baseUrl = '';
  let path = 'data.json';

  if (process.env.REACT_APP_API_BASE_URL && id !== null && token !== null) {
    baseUrl = process.env.REACT_APP_API_BASE_URL;
    path = `flows/patients/${id}/data-viz?token=${token}`;
  }

  return `${baseUrl}/${path}`;
}
