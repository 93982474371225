import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/raleway/300.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';

const customTheme = extendTheme({
  fonts: {
    heading: `'Raleway', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
  colors: {
    green: {
      50: '#ECFAF2',
      100: '#D8F4E5',
      200: '#B1E9CB',
      300: '#8ADFB2',
      400: '#63D498',
      500: '#3CC97E',
      600: '#32BC6F',
      700: '#28AE60',
      800: '#1EA151',
      900: '#149342',
    },
    yellow: {
      50: '#FFFAED',
      100: '#FFF4DB',
      200: '#FFE9B6',
      300: '#FEDE92',
      400: '#FED36D',
      500: '#FEC849',
      600: '#F1B63C',
      700: '#E4A42E',
      800: '#D89321',
      900: '#CB8114',
    },
    blue: {
      50: '#E7EAEE',
      100: '#CED5DD',
      200: '#9DABBB',
      300: '#6B8099',
      400: '#3A5677',
      500: '#092C55',
      600: '#072447',
      700: '#051C38',
      800: '#04132A',
      900: '#020B1B',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={customTheme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
