import React from 'react';
import {
  Area,
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomTooltip from './CustomTooltip';
import { RecipeChartsStep } from '../../domain/chartsMapper';

type Props = {
  data: RecipeChartsStep[];
};

type ChartsKeys = keyof RecipeChartsStep;
const config: {
  axisName: Record<string, string>;
  dataKey: { [key in ChartsKeys]: ChartsKeys };
} = {
  axisName: {
    timeDifference: 'timeDifference',
    progression: 'progression',
    levelOfDetail: 'levelOfDetail',
  },
  dataKey: {
    stepId: 'stepId',
    taskName: 'taskName',
    timeDifference: 'timeDifference',
    progression: 'progression',
    levelOfDetail: 'levelOfDetail',
    rollback: 'rollback',
    helpOrSolutionAsked: 'helpOrSolutionAsked',
    detailAsked: 'detailAsked',
    debugInfo: 'debugInfo',
  },
};

const Chart: React.FC<Props> = ({ data }): JSX.Element => {
  const dataMax = Math.max(...data.map((d) => d.timeDifference));
  const axisThreshold = dataMax > 100 ? 100 : dataMax;
  return (
    <ResponsiveContainer minWidth={900} minHeight={500}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 100,
          left: 20,
        }}
        style={{ backgroundColor: '#f6f6f6', fontSize: '14px' }}
      >
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis dataKey={config.dataKey.stepId} hide />
        <YAxis
          yAxisId={config.axisName.timeDifference}
          label={{
            value: '% Diff temps',
            angle: -90,
            position: 'insideLeft',
            style: { textAnchor: 'middle' },
          }}
          domain={[-axisThreshold, axisThreshold]}
          allowDataOverflow
        />
        <YAxis
          yAxisId={config.axisName.progression}
          dataKey={config.dataKey.progression}
          orientation="right"
          tickCount={10}
          domain={[0, 100]}
          label={{
            value: 'Avancement',
            angle: -90,
            position: 'insideRight',
            style: { textAnchor: 'middle' },
          }}
        />
        <YAxis
          yAxisId={config.axisName.levelOfDetail}
          dataKey={config.dataKey.levelOfDetail}
          orientation="right"
          tickCount={10}
          domain={[0, 30]}
          hide
        />
        <Legend
          verticalAlign="top"
          iconSize={8}
          payload={[
            {
              value: '% Diff temps nominal',
              type: 'rect',
              color: '#66CC99',
              id: 'nominalTimeDifferenceLegend',
            },
            {
              value: 'Avancement recette en %',
              type: 'line',
              color: '#000066',
              id: 'recipeProgressLegend',
            },
            { value: 'Niveau de détail', type: 'rect', color: '#6D9EEB', id: 'detailLevelLegend' },
            {
              value: 'Demande de détail',
              type: 'triangle',
              color: '#9E7BB5',
              id: 'detailAskedLegend',
            },
            {
              value: 'Demande aide-solution',
              type: 'triangle',
              color: '#ff7300',
              id: 'helpOrSolutionAskedLegend',
            },
            { value: 'Retour', type: 'square', color: '#FF0033', id: 'rollbackLegend' },
          ]}
        />
        <Bar
          dataKey={config.dataKey.timeDifference}
          yAxisId={config.axisName.timeDifference}
          barSize={20}
          fill="#66CC99"
        />
        <Line
          dataKey={config.dataKey.progression}
          yAxisId={config.axisName.progression}
          type="monotone"
          stroke="#000066"
        />
        <Scatter
          dataKey={config.dataKey.helpOrSolutionAsked}
          yAxisId={config.axisName.timeDifference}
          fill="#ff7300"
          shape="triangle"
        />
        <Scatter
          dataKey={config.dataKey.detailAsked}
          yAxisId={config.axisName.timeDifference}
          fill="#9E7BB5"
          shape="triangle"
        />
        <Scatter
          dataKey={config.dataKey.rollback}
          yAxisId={config.axisName.timeDifference}
          fill="#FF0033"
          shape="square"
        />
        <Area
          type="step"
          dataKey={config.dataKey.levelOfDetail}
          yAxisId={config.axisName.levelOfDetail}
          fill="#6D9EEB50"
          stroke="#6D9EEB"
        />
        <Tooltip content={<CustomTooltip />} />
        <Brush height={30} stroke="#8884d8" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export default Chart;
