import { RecipePreparation, StepId } from './backend.model';
import {
  InputData,
  DataWithPreparation,
  PreparationStep,
  RecipePreparationStep,
  RecipeIndicators,
  IndicatorsWithPreparation,
} from './frontend.model';
import { sortDateWithMoreRecentFirst } from '../helpers';

export const mergeIndicatorsWithPreparation = (inputData: InputData): DataWithPreparation => {
  return {
    ...inputData,
    recipesIndicators: inputData.recipesIndicators
      .map((indicators): IndicatorsWithPreparation | undefined => {
        if (!inputData.recipesPreparation) return undefined;

        const preparation = getPreparationOfRecipeIndicators(indicators, inputData.recipesPreparation);
        if (!preparation) return undefined;

        return {
          ...indicators,
          preparation: {
            name: preparation.name,
            steps: getPreparationByStep(preparation.steps),
          },
        };
      })
      // exclude prevs undefined items
      .filter(
        (indicatorsWithPreparation): indicatorsWithPreparation is IndicatorsWithPreparation =>
          !!indicatorsWithPreparation
      )
      .sort((a, b) => sortDateWithMoreRecentFirst(a.startDate, b.startDate)),
  };
};

const getPreparationOfRecipeIndicators = (
  indicators: RecipeIndicators,
  recipesPreparation: RecipePreparation[]
): RecipePreparation | null => {
  const matchByIdAndVersion = recipesPreparation.find((p) => {
    return p.id === indicators.recipeId && p.recipeVersion === indicators.recipeVersion;
  });
  if (matchByIdAndVersion) return matchByIdAndVersion;

  const matchByIdOnly = recipesPreparation.find((p) => p.id === indicators.recipeId);
  if (matchByIdOnly) return matchByIdOnly;

  return null;
};

const getPreparationByStep = (steps: RecipePreparationStep[]) => {
  const preparationByStep: { [key: StepId]: PreparationStep } = {};

  const percentageByStep = 100 / steps.length;
  const orderedSteps = steps.sort((a, b) => a.order - b.order);

  let acc = percentageByStep;
  for (const step of orderedSteps) {
    preparationByStep[step.id] = {
      partOfTaskNsCompletion: acc,
      ...step,
    };
    acc += percentageByStep;
  }

  return preparationByStep;
};
