import dayjs from 'dayjs';

export const groupListeItemByProperty = <T>(list: T[], getDiscriminant: (item: T) => string): { [p: string]: T[] } =>
  list.reduce((obj: { [p: string]: T[] }, item: T) => {
    const discriminant = getDiscriminant(item);

    // eslint-disable-next-line no-prototype-builtins
    if (!obj.hasOwnProperty(discriminant)) {
      obj[discriminant] = [];
    }
    obj[discriminant].push(item);

    return obj;
  }, {});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const displayDate = (isoDate: string) => {
  const date = dayjs(isoDate);

  return {
    yearToDay: () => date.format('YYYY-MM-DD'),
    yearToMinutes: () => date.format('YYYY-MM-DD hh[h]mm'),
  };
};

export const sortDateWithMoreRecentFirst = (a: string, b: string): -1 | 0 | 1 => {
  if (dayjs(a).isBefore(dayjs(b))) return 1;
  if (dayjs(a).isAfter(dayjs(b))) return -1;
  return 0;
};
