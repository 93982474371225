import { IndicatorsWithPreparation, RecipeIndicators, PreparationStep } from './frontend.model';

export interface RecipeChartsStep {
  stepId: string;
  taskName: string;
  progression: number;
  timeDifference: number;
  levelOfDetail: number;
  rollback?: number; // use to active
  helpOrSolutionAsked?: number; // use to active
  detailAsked?: number; // use to active
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debugInfo: any;
}

export const getRecipeDataForCharts = (recipeIndicators: IndicatorsWithPreparation): RecipeChartsStep[] => {
  const currentPreparationSteps = recipeIndicators.preparation.steps;

  return recipeIndicators.path
    .filter((taskIndicator) => {
      console.warn(
        `Step ${taskIndicator.stepId} of recipe ${recipeIndicators.recipeId} don't exist anymore so the graph could have a hole!`
      );
      return currentPreparationSteps[taskIndicator.stepId] !== undefined;
    })
    .map((taskIndicator) => {
      const currentStepPreparation = currentPreparationSteps[taskIndicator.stepId];

      const timeDiff = getTimeDifference(taskIndicator, currentStepPreparation);
      const taskDone = getPreparationTaskDoneByUser(taskIndicator, currentStepPreparation);
      const rollback = taskIndicator.numberOfReturnRequests > 0;

      return {
        stepId: taskIndicator.stepId,
        taskName: taskDone.task.name,
        progression: currentStepPreparation.partOfTaskNsCompletion,
        timeDifference: timeDiff,
        levelOfDetail: taskDone.level,
        rollback: rollback ? 0 : undefined,
        helpOrSolutionAsked: taskIndicator.numberOfHelpRequests > 0 ? 0 : undefined,
        detailAsked: taskIndicator.numberOfDetailRequests > 0 ? 0 : undefined,
        debugInfo: {
          id: taskDone.task.id,
          type: taskDone.task.type,
          duration: taskDone.task.duration,
        },
      };
    });
};

type Path = RecipeIndicators['path'][0];

const getTimeDifference = (path: Path, stepPreparation: PreparationStep): number => {
  if (path.durationInSeconds === undefined) return 0; // O seconde

  const taskDone = getPreparationTaskDoneByUser(path, stepPreparation);

  // TODO: temp, le back doit normaliser les champs
  if (['[Chapitre]', 'Ingrédients', 'Ustensiles'].includes(taskDone.task.type)) return 0;

  return path.durationInSeconds - taskDone.duration;
};

// A step could have taskN, tasksNm1 or tasksNm2 and once could be done
const getPreparationTaskDoneByUser = (
  taskIndicator: Path,
  stepPreparation: PreparationStep
): {
  level: number;
  duration: number;
  task: {
    id: string;
    type: string;
    name: string;
    duration: number;
  };
} => {
  const taskIdDoneByUser = taskIndicator.taskId;

  const taskNPreparation = stepPreparation.taskN;
  const taskN = {
    level: 0,
    duration: stepPreparation.taskN.duration,
    task: {
      id: stepPreparation.taskN.id,
      type: stepPreparation.type,
      name: stepPreparation.taskN.name,
      duration: stepPreparation.taskN.duration,
    },
  };
  if (taskNPreparation.id === taskIdDoneByUser) return taskN;

  const taskNm1Preparation = taskNPreparation.tasksNm1;
  const matcheTaskNm1 = taskNm1Preparation?.find((nm1) => nm1.id === taskIdDoneByUser);
  if (matcheTaskNm1) {
    return {
      level: -1,
      duration: matcheTaskNm1.duration,
      task: {
        id: matcheTaskNm1.id,
        type: stepPreparation.type,
        name: matcheTaskNm1.name,
        duration: matcheTaskNm1.duration,
      },
    };
  }

  const matcheTaskNm2 = taskNm1Preparation?.find((nm1) => nm1.tasksNm2?.find((nm2) => nm2.id === taskIdDoneByUser));
  if (matcheTaskNm2) {
    return {
      level: -2,
      duration: matcheTaskNm2.duration,
      task: {
        id: matcheTaskNm2.id,
        type: stepPreparation.type,
        name: matcheTaskNm2.name,
        duration: matcheTaskNm2.duration,
      },
    };
  }

  return taskN;
};
