import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { IndicatorsWithPreparation } from '../domain/frontend.model';
import { indicatorsPropertiesByRecipeToRender } from '../domain/indicatorsMapper';
import RecipeHeader from './RecipeHeader';
import RecipeItem from './RecipeItem';
import { displayDate } from '../helpers';

type Props = {
  indicators: IndicatorsWithPreparation[];
};

const RecipeList: React.FC<Props> = ({ indicators }) => {
  const [indexOpenPanel, setIndexOpenPanel] = useState(0);

  return (
    <Box p={4} color="blue.500">
      <Heading pb={4} color="blue.500" as="h3" size="md">
        Sélection pour indicateurs détaillés
      </Heading>
      <Accordion
        allowToggle
        defaultIndex={[0]}
        onChange={(expandedIndex) => {
          setIndexOpenPanel(expandedIndex as number /* no arrray once open */);
        }}
      >
        {indicators.map((r, index) => {
          const startDate = displayDate(r.startDate).yearToDay();
          return (
            <AccordionItem key={r.recipeInstanceId}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <RecipeHeader id={r.recipeId} formattedStartDate={startDate} recipeLabel={r.preparation.name} />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box p={4}>
                  {indexOpenPanel === index && (
                    <RecipeItem recipeDetailRenderer={indicatorsPropertiesByRecipeToRender} recipe={r} />
                  )}
                </Box>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Box>
  );
};

export default RecipeList;
