import React from 'react';
import { TooltipProps } from 'recharts';
import './CustomTooltip.css';
import { RecipeChartsStep } from '../../domain/chartsMapper';
import { IS_NOT_ENV_PROD } from '../../CONST';

type PropPayload = RecipeChartsStep;

const getDetailLabel = (levelOfDetail: number) => {
  switch (levelOfDetail) {
    case 0:
      return 'global';
    case -1:
      return 'détaillé';
    case -2:
      return 'très détaillé';
    default:
      return '';
  }
};

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }): JSX.Element => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!(active && payload && payload.length)) return <></>;

  const data: PropPayload = payload[0].payload;
  const detailLabel = getDetailLabel(data.levelOfDetail);

  return (
    <div className="custom-tooltip">
      <p className="step">
        <span className="bold">Consigne : </span> {data.taskName}
      </p>
      <p className="progress">
        <span className="bold">Avancement : </span>
        {Math.round(data.progression)}%
      </p>
      <p className="time-diff">
        <span className="bold">Diff. temps nominal : </span>
        {data.timeDifference}%
      </p>
      <p className="detail-level">
        <span className="bold">Niveau : </span>
        {detailLabel}
      </p>
      <p className={data.detailAsked !== undefined ? 'detail-asked bold' : ''}>
        Demande de détail : {data.detailAsked !== undefined ? 'Oui' : 'Non'}
      </p>
      <p className={data.helpOrSolutionAsked !== undefined ? 'help-asked bold' : ''}>
        Demande d&apos;aide-solution : {data.helpOrSolutionAsked !== undefined ? 'Oui' : 'Non'}
      </p>
      <p className={data.rollback !== undefined ? 'rollback bold' : ''}>
        Retour : {data.rollback !== undefined ? 'Oui' : 'Non'}
      </p>
      <p className="step">
        <span>Étape n° : </span> {data.stepId}
      </p>
      {IS_NOT_ENV_PROD && (
        <div>
          <pre>{JSON.stringify(data.debugInfo, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};
export default CustomTooltip;
