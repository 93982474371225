import React, { useEffect, useState } from 'react';
import './TableWrapper.css';
// eslint-disable-next-line import/no-cycle
import { getRecipeDataForCharts, RecipeChartsStep } from '../domain/chartsMapper';
import { IndicatorsWithPreparation } from '../domain/frontend.model';
import { lang, Lang } from '../domain/lang';
import Chart from './Chart/Chart';

export type IndicatorsPropertiesByRecipeToRender = {
  keyForJSX: number;
  title: { [key in Lang]: string };
  recipeProperty?: keyof IndicatorsWithPreparation;
  render?: (recipe: IndicatorsWithPreparation) => JSX.Element;
};

type Props = {
  recipeDetailRenderer: IndicatorsPropertiesByRecipeToRender[];
  recipe: IndicatorsWithPreparation;
};

const RecipeItem: React.FC<Props> = ({ recipeDetailRenderer, recipe }) => {
  const [recipeChartsData, setRecipeChartsData] = useState<RecipeChartsStep[] | null>(null);

  useEffect(() => {
    setRecipeChartsData(getRecipeDataForCharts(recipe));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {recipeChartsData && <Chart data={recipeChartsData} />}
      <br />
      <ul>
        {recipeDetailRenderer.map((item) => {
          let value: unknown = '';

          // Use directly the value when property exists
          if (item.recipeProperty && recipe[item.recipeProperty]) {
            value = recipe[item.recipeProperty];
          }
          if (item.render) value = item.render(recipe);

          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <li key={item.keyForJSX}>
              <strong>{item.title[lang]}</strong> : {value}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default RecipeItem;
